import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";
import posthog from 'posthog-js'

const { REACT_APP_PUBLIC_POSTHOG_HOST: postHogHost, REACT_APP_PUBLIC_POSTHOG_KEY: postHogKey } = process.env;

postHogKey && posthog.init(postHogKey,
    {
        api_host: postHogHost,
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: ["NotAllowedError"],
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// Test comment
serviceWorker.unregister();
